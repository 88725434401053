import React, { useState, useEffect, useRef } from 'react';
import './Typewriter.css'; // Import the CSS file

const Typewriter = ({ text, speed = 50, onComplete }) => {
  const [displayedText, setDisplayedText] = useState([]);
  const [currentText, setCurrentText] = useState('');
  const textIndex = useRef(0);
  const intervalId = useRef(null);

  // Function to parse displayed text into JSX elements
  const parseDisplayedText = (textArray) => {
    return textArray.map((char, index) => {
      if (char === ' ') {
        // Render non-breaking space for spaces
        return <span key={index} className="fade-in">&nbsp;</span>;
      } else if (char === '\n') {
        // Render a line break for newline characters
        return <br key={index} />;
      } else {
        // Render the character itself
        return <span key={index} className="fade-in">{char}</span>;
      }
    });
  };

  useEffect(() => {
    const type = () => {
      if (textIndex.current < currentText.length) {
        setDisplayedText((prevDisplayedText) => {
          // Find the next space or end of text to type the whole word
          let nextSpaceIndex = currentText.indexOf(' ', textIndex.current);
          if (nextSpaceIndex === -1) nextSpaceIndex = currentText.length;
          const newText = currentText.slice(textIndex.current, nextSpaceIndex) + " ";
          
          // Move the index forward
          textIndex.current = nextSpaceIndex + 1; // Skip past the space

          // Check for completion condition
          if (newText.includes('[') && onComplete) {
            cancelAnimationFrame(intervalId.current);
            onComplete();
          }

          return [...prevDisplayedText, newText];
        });
      }
    };

    const step = () => {
      type();
      intervalId.current = setTimeout(step, speed);
    };

    intervalId.current = setTimeout(step, speed);

    return () => clearTimeout(intervalId.current);
  }, [currentText, speed, onComplete]);

  useEffect(() => {
    if (text.length > displayedText.length) {
      setCurrentText(text);
    }
  }, [text]);

  return (
    <div className="typewriter">
      {parseDisplayedText(displayedText)}
    </div>
  );
};

export default Typewriter;
