import "./Header.css";
import { Button, Dropdown, Image, Modal } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import PrimaryBtn from "../CommonBtn/PrimaryBtn";
import { showPopUpAction } from "../../Redux/Slices/loginPopUpSlice";
import { useDispatch, useSelector } from "react-redux";
import { Logout } from "../../Utils/Utils";
import User from "../../Models/User";
import { updateUserData, userDataSelector } from "../../Redux/Slices/userDataSlice";
import { ProfilePicture } from "../ProfilePicture/ProfilePicture";
import { t } from "i18next";
import NotificationDropdown from "./HeaderNotificationSystem";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import Select, { components } from "react-select";
import { useEffect, useRef, useState } from "react";
import { ChatbotSearchData, GetSemanticSearchList } from "../../Utils/ChatbotUtils";
import { toast } from "react-hot-toast";
import AsyncSelect from "react-select/async";
import PopupModal from "../PopUps/PopUpBase";

const ChatbotButton = () => (
  <Link to="/createaicompanion" className="btn btn-primary m-auto w-50 text-center justify-content-center"><span><i><Icon icon="heroicons:sparkles-solid" /></i></span> Create personalized bot</Link>
);

interface ComponentProps {
  setOpenSetting: (val: boolean) => void;
}

const initialOptions = [

];

var queriedString = "";
const Header = ({ setOpenSetting }: ComponentProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userData = useSelector(userDataSelector);
  const [searchString, setSearchString] = useState("aang");
  const [displayedChatbotNames, setDisplayedChatbotNames] = useState<ChatbotOption[]>([]);
  const [isLoadBarLoading, setIsLoadBarLoading] = useState(true);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (userData.Orbox < 10) {
      setShowModal(true);
    }
  }, [userData.Orbox]);

  type ChatbotOption = {
    value: ChatbotSearchData,
    label: string
  }
  useEffect(() => {
    // onLoadMoreData();
  }, []);

  const handleInputChange = (inputValue: string) => {
    const lowerCaseInputValue = inputValue.toLowerCase();
    setSearchString(lowerCaseInputValue);
    queriedString = lowerCaseInputValue;
  };

  const onLoadMoreData = () => {
    return GetSemanticSearchList(queriedString).then((response: ChatbotSearchData[]) => {
      const optionsChatbots = [] as ChatbotOption[];
      for (const chatbot of response) {
        optionsChatbots.push({ value: chatbot, label: chatbot.name });
      }
      setIsLoadBarLoading(false);
      setDisplayedChatbotNames(optionsChatbots.slice(0, 35)); // Initially display only the first 5 chatbots
      return optionsChatbots;
    });
  };

  function OnOpenLoginPopup() {
    dispatch(showPopUpAction(true));
  }

  const onSidebarToggle = () => {
    const bodyElement = document.querySelector("body");
    if (bodyElement) {
      bodyElement.classList.toggle("SidebarToggle");
    }
  };
  function OpenSelectedChatbot(selectedOption: ChatbotOption) {
    if (selectedOption === null) {
      return;
    }
    window.open(`/chat/${selectedOption?.string_id}`);
  }

  const [checked, setChecked] = useState(false);
  const checkboxRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (checkboxRef.current && !checkboxRef.current.contains(event.target)) {
        setChecked(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChatbotSearchClick = (e: any) => {
    if (e.key === "Enter") {
      navigate(`/search/${searchString}`);
    }

  };
  if (location.pathname.toLowerCase().split("/")[1] !== "chat") {
    return (
      <>
        <header className="mainheader">
          <span className="header-span-bg"></span>
          <div className="header-left-wrap d-flex align-items-center">
            <Link className="header-menu-icon" onClick={onSidebarToggle} to="javascript:void(0)"><Icon icon="ic:round-menu" /></Link>
            <Link to="/" className="nav-logo"><Image src="/images/logo.png" /></Link>
            <Link to="/" className="nav-logo lightlogo"><Image src="/images/logo-light.png" /></Link>

          </div>
          <div className="Header-Searchbar" ref={checkboxRef}>
            <input type="checkbox" className="searchinput" checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />
            <Link to="/"><Icon icon="iconamoon:search" /></Link>
            <AsyncSelect
              isLoading={isLoadBarLoading}
              isClearable={true}
              value={[]}
              defaultOptions={initialOptions}
              // loadOptions={onLoadMoreData}
              isSearchable={true}
              placeholder="Search"
              onInputChange={handleInputChange}
              onKeyDown={handleChatbotSearchClick}
              formatOptionLabel={({ value, label }) => (
                <div style={{ display: "flex", alignItems: "center" }} onClick={() => OpenSelectedChatbot(value)}>
                  {value && value.img && <img src={value.img} style={{ marginRight: "10px", width: "32px", height: "32px" }} />}
                  {label}
                </div>
              )}
              styles={{
                option: (base) => ({
                  ...base,
                  cursor: "pointer",
                  ":hover": {
                    backgroundColor: "#0d0d0d",
                  },
                }),
                input: (provided) => ({
                  ...provided,
                  color: "white",
                  backgroundColor: "transparent",
                }),
              }}
              components={{
                Menu: (props) => (
                  <components.Menu {...props} className="searchmenu">
                    {props.children}
                    <div className="ChatCreateBtnSearch">
                      <ChatbotButton />
                    </div>
                  </components.Menu>
                ),
                LoadingMessage: () => null,
                NoOptionsMessage: () => null 
              }}
            />
          </div>

          <div className="header-right-wrap mainheader">
            {localStorage.getItem("oauthToken") ?
              <>
                <Link to="/createaicompanion" className="Header-spark-btn creataibtn"><span><i><Icon icon="heroicons:sparkles-solid" /></i> {t("create_ai")}</span></Link>
                <Link
                  to="/pricing"
                  className="Header-spark-btn d-flex Header-profile-orboxbox"
                >
                  <i>
                    <Image src="/images/logo-coin.svg" className="orbofiicon" />
                  </i>
                  {userData.Orbox + userData.PremiumOrbox}
                  <small>
                    <Icon icon="basil:add-solid" />
                  </small>
                </Link>
                <NotificationDropdown />
                <ProfileDropdown setOpenSetting={setOpenSetting} />
              </>
              :
              <>
                <Link to="/" className="Header-spark-btn creataibtn" onClick={OnOpenLoginPopup}><span><i><Icon icon="heroicons:sparkles-solid" /></i> {t("create_ai")}</span></Link>
                <PrimaryBtn child={<>{t("login")}</>} className={"LoginHeaderBtn"} linkName={"/"} onClick={OnOpenLoginPopup} />


              </>
            }
          </div>
        </header>
      </>
    );
  } else {
    return null;
  }
};

function ProfileDropdown({ setOpenSetting }: ComponentProps): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector(userDataSelector);

  function OnHandleLogout() {
    Logout();
    const userData = new User();
    dispatch(updateUserData(userData));
    navigate("/");
  }
  const [isOpen, setIsOpen] = useState(false);
  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleDropdownClose = () => {
    setIsOpen(false);
  };

  return (
    <Dropdown className="profile-dropdown" show={isOpen} onToggle={handleDropdownToggle} onClose={handleDropdownClose}>
      <Dropdown.Toggle variant="" id="dropdown-basic">
        <div className="Header-profile-main">
          <span>
            <ProfilePicture />
          </span>
          <h6>{userData.Name} <i><Icon icon="ep:arrow-down-bold" /></i></h6>
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className="profile-dropdown-main">
        <div className="dropdown-bg">
          <ul className="profile-dropdown-list">
            <li className="pb-3">
              <ConnectButton label="connect_wallet" />
            </li>
            <li>
              <Link to={"/profile/" + userData.Name} onClick={handleDropdownClose}>
                <Icon icon="ep:user" />My Profile
              </Link>
            </li>
            <li>
              <Link to={"/profile/" + userData.Name} onClick={handleDropdownClose}>
                <Icon icon="carbon:user-profile" />My Smart companions
              </Link>
            </li>
            <li>
              <Link to="javascript:void(0)" onClick={() => { handleDropdownClose(); setOpenSetting(true); }}>
                <Icon icon="lets-icons:setting-line-light" />Settings
              </Link>
            </li>
            <li>
              <Link to="javascript:void(0)" onClick={() => { handleDropdownClose(); OnHandleLogout(); }}>
                <Icon icon="mdi-light:logout" />Logout
              </Link>
            </li>
          </ul>
        </div >
      </Dropdown.Menu >
    </Dropdown >
  );
}
export default Header;