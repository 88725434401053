import React, { useState } from "react";
import {
  ScrollMenu,
  VisibilityContext,
  type publicApiType,
} from "react-horizontal-scrolling-menu";
import { Link, useNavigate } from "react-router-dom";
import LordIcon from "../../Components/LordIcon/LordIcon";
import { t } from "i18next";
import { Icon } from "@iconify/react";
import { Button, Image, Modal } from "react-bootstrap";
import { Steps, Hints } from "intro.js-react";
import IntroJs from "intro.js";
import "intro.js/introjs.css";
import { useSelector } from "react-redux";
import { subDataSelector } from "../../Redux/Slices/subscriptionDataSlice";
import PopupModal from "../../Components/PopUps/PopUpBase";
import PricingPopup from "../PricingPage/PricingPopup";
import PricingTabs from "../PricingPage/Components/PricingTabs/PricingTabs";
import Products from "../../Models/Products";

interface ChatScreenFooterProps {
  activeOption: string;
  setIsChatScreenAiAgentImgClicked: (img: number) => void;
  handleOptionClick: (selectedOption: string) => void;
}
const ChatScreenGenerateOptions = ({ activeOption, handleOptionClick, setIsChatScreenAiAgentImgClicked }) => {
  const [items] = React.useState(() => getItems());
  const [selected, setSelected] = React.useState<string[]>([]);
  const subData = useSelector(subDataSelector);
  const [showPaymentScreen, setShowPaymentScreen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(new Products());
  const [showModal, setShowModal] = useState(false);
  const [activeStepIndex, setActiveStepIndex] = useState(0); // Track the index of the active step
  const [introCompleted, setIntroCompleted] = useState<boolean>(localStorage.getItem("introBots") == "completed"); // Track if the intro.js tour has been completed

  // NOTE: for drag by mouse
  const dragState = React.useRef(new DragManager());

  const handleDrag = ({ scrollContainer }: typeof VisibilityContext) => (ev: React.MouseEvent) => {
    dragState.current.dragMove(ev, (posDiff) => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft += posDiff;
      }
    });
  };

  const onMouseDown = React.useCallback(() => dragState.current.dragStart, [dragState]);
  const onMouseUp = React.useCallback(() => dragState.current.dragStop, [dragState]);

  const isItemSelected = (id: string): boolean => !!selected.find((el) => el === id);

  const handleItemClick = (itemId: string) => {
    if (dragState.current.dragging) {
      return false;
    }
    const itemSelected = isItemSelected(itemId);

    setSelected((currentSelected: string[]) =>
      itemSelected
        ? currentSelected.filter((el) => el !== itemId)
        : currentSelected.concat(itemId),
    );
  };
  const navigate = useNavigate();

  const handleOptionClickMain = (destination) => {
    navigate(destination); // Navigate to the specified destination
  };

  const onExit = () => { localStorage.setItem("introBots", "completed"); setIntroCompleted(true); };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const selectModalOption = (event: any) => {
    if((subData?.SubscriptionLevel > 1)) {
      handleOptionClick(event);
    } else {
      setShowModal(true);
    }
  };
  return (
    <>
      <div className="ChatScreenOptions" onMouseLeave={dragState.current.dragStop}>
        <ScrollMenu
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={handleDrag}
          onWheel={onWheel}
        >
          <li><Button id='step1' variant="" onClick={() => { handleOptionClick("ask_and_chat"); setIsChatScreenAiAgentImgClicked(0); }} className={activeOption === "ask_and_chat" ? "active" : ""}><i>
            <LordIcon src="https://cdn.lordicon.com/obawqdgi.json" trigger="loop" colors="primary:#ffffff,secondary:#ebe6ef,tertiary:#54abb1"></LordIcon>
          </i> {t("ask_and_chat")}</Button></li>
          <li><Button id='step2' variant="" onClick={() => handleOptionClick("image_generation")} className={activeOption === "image_generation" ? "active" : ""}><i>
            <LordIcon src="https://cdn.lordicon.com/pbhjpofq.json" trigger="loop" colors="primary:#ffffff,secondary:#54abb1,tertiary:#4bb3fd,quaternary:#ffc738,quinary:#f24c00,senary:#ffffff,septenary:#f9c9c0"></LordIcon>
          </i>{t("image_generation")}</Button></li>
          <li><Button id='step3' variant="" onClick={() => {
            handleOptionClick("website");
            setIsChatScreenAiAgentImgClicked(2);
          }} className={activeOption === "website" ? "active" : ""}><i>
              <LordIcon src="https://cdn.lordicon.com/ejcvkxer.json" trigger="loop" colors="primary:#ffffff,secondary:#ffc738,tertiary:#f24c00,quaternary:#54abb1,quinary:#4bb3fd,senary:#fafafa,septenary:#ebe6ef"></LordIcon>
            </i> {t("website_generation")} </Button></li>
          <li><Button id='step4' variant="" onClick={() => {
            handleOptionClick("code");
            setIsChatScreenAiAgentImgClicked(2);
          }} className={activeOption === "code" ? "active" : ""}><i>
              <LordIcon src="https://cdn.lordicon.com/ejcvkxer.json" trigger="loop" colors="primary:#ffffff,secondary:#ffc738,tertiary:#f24c00,quaternary:#54abb1,quinary:#4bb3fd,senary:#fafafa,septenary:#ebe6ef"></LordIcon>
            </i> {t("code")} </Button></li>
          <li><Button id='step5' variant="" onClick={() => selectModalOption("GPT-4")} className={activeOption === "GPT-4" ? "active" : ""}><i>
            <LordIcon src="https://cdn.lordicon.com/ucekksbn.json" trigger="loop" colors="primary:#ffffff,secondary:#ffffff,tertiary:#54abb1"></LordIcon>
          </i> GPT-4 </Button>{![2,3].includes(subData?.SubscriptionLevel) ? <span className="protag"><Icon icon="noto-v1:crown" /></span> : '' }</li>
          {/* <li><Button id="step6" variant=""><i>
            <LordIcon src="https://cdn.lordicon.com/pwwdvajw.json" trigger="loop" colors="primary:#ffffff,secondary:#ffffff,tertiary:#54abb1"></LordIcon>
          </i> {t('longform_text')} </Button></li> */}
          <li><Button id='step7' variant="" onClick={() => selectModalOption("vision_power")} className={activeOption === "vision_power" ? "active" : ""}><i>
            <LordIcon src="https://cdn.lordicon.com/xkkkkglu.json" trigger="loop" colors="primary:#ffffff,secondary:#54abb1,tertiary:#ffffff"></LordIcon>
          </i> {t("vision_power")} </Button>{![2,3].includes(subData?.SubscriptionLevel) ? <span className="protag"><Icon icon="noto-v1:crown" /></span> : '' }</li>
          <li><Button variant="" onClick={handleShow}><i>
            <LordIcon src="https://cdn.lordicon.com/obawqdgi.json" trigger="loop" colors="primary:#ffffff,secondary:#ebe6ef,tertiary:#54abb1"></LordIcon>
          </i> {t('more')}</Button></li>
          <div><Steps enabled={!(localStorage.getItem("introBots") == "completed")} steps={[]} initialStep={0} onExit={onExit} /></div>
        </ScrollMenu>
      </div >
      <Modal show={show} onHide={handleClose} centered className='default-modal parametermodal chatmainmodal'>
        <div className='default-modal-header chatmaintitle'>
          <h2>Coming Soon</h2>
          <Button variant="" onClick={handleClose}><Icon icon="ion:close-circle-outline" /></Button>
        </div>
        <Modal.Body>
          <ul className="ChatScreenOptions chatbtnmodalmain" onMouseLeave={dragState.current.dragStop}>
            <li><Button variant="" onClick={handleShow}><i>
              <LordIcon src="https://cdn.lordicon.com/obawqdgi.json" trigger="loop" colors="primary:#ffffff,secondary:#ebe6ef,tertiary:#54abb1"></LordIcon>
            </i>+ Connect your own model </Button></li>
          </ul >
          <Link to="javascript:void(0)" className="btn-primary justify-content-center">Notify Me</Link>
        </Modal.Body>
      </Modal>
      {localStorage.getItem("oauthToken") ? (
        <>
          <PopupModal
            show={showModal}
            handleClose={() => {setShowModal(false)}}
            className={`default-popup login-popup  ${
              !showPaymentScreen ? "PricingPopupMain" : "PricingPaymentPopup"
            }`}
            backdrop={undefined}
          >
            {!showPaymentScreen ? (
              <Link
                to="javascript:void(0)"
                className="CloseBtn"
                onClick={() => {setShowModal(false)}}
              >
                <Icon icon="solar:close-circle-linear" />
              </Link>
            ) : (
              ""
            )}
            {!showPaymentScreen ? (
              <PricingTabs
                isFaq={false}
                onPaymentToggle={() => setShowPaymentScreen(true)}
                setCurrentProduct={setCurrentProduct}
                isPopup={true}
              />
            ) : (
              <PricingPopup
                onPaymentToggle={() => setShowPaymentScreen(false)}
                product={currentProduct}
              />
            )}
          </PopupModal>
        </>
      ) : (
        ""
      )}
    </>
  );
};
export default ChatScreenGenerateOptions;

class DragManager {
  clicked: boolean;
  dragging: boolean;
  position: number;

  constructor() {
    this.clicked = false;
    this.dragging = false;
    this.position = 0;
  }

  public dragStart = (ev: React.MouseEvent) => {
    this.position = ev.clientX;
    this.clicked = true;
  };

  public dragStop = () => {
    window.requestAnimationFrame(() => {
      this.dragging = false;
      this.clicked = false;
    });
  };

  public dragMove = (ev: React.MouseEvent, cb: (posDiff: number) => void) => {
    const newDiff = this.position - ev.clientX;

    const movedEnough = Math.abs(newDiff) > 5;

    if (this.clicked && movedEnough) {
      this.dragging = true;
    }

    if (this.dragging && movedEnough) {
      this.position = ev.clientX;
      cb(newDiff);
    }
  };
}


function LeftArrow() {
  const { initComplete, isFirstItemVisible, scrollPrev } =
    React.useContext<publicApiType>(VisibilityContext);

  return (
    <Arrow
      disabled={!initComplete || (initComplete && isFirstItemVisible)}
      onClick={() => scrollPrev()}
      testId="left-arrow"
    >
      <Icon icon="iconamoon:arrow-left-2-bold" />
    </Arrow>
  );
}

function RightArrow() {
  const { initComplete, isLastItemVisible, scrollNext } =
    React.useContext<publicApiType>(VisibilityContext);

  return (
    <Arrow
      disabled={initComplete && isLastItemVisible}
      onClick={() => scrollNext()}
      testId="right-arrow"
    >
      <Icon icon="iconamoon:arrow-right-2-bold" />
    </Arrow>
  );
}

function Arrow({
  children,
  disabled,
  onClick,
  className,
  testId,
}: {
  children: React.ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
  className?: string;
  testId: string;
}) {
  return (
    <button type='button'
      disabled={disabled}
      onClick={onClick}
      className={"arrow" + `-${className}`}
      data-testid={testId}
    >
      {children}
    </button>
  );
}


function Card({
  onClick,
  selected,
  title,
  itemId,
}: {
  onClick: (context: publicApiType) => void;
  selected: boolean;
  title: string;
  itemId: string;
}) {
  const visibility = React.useContext<publicApiType>(VisibilityContext);

  const visible =
    !visibility.initComplete ||
    (visibility.initComplete && visibility.isItemVisible(itemId));

  return (
    <div
      data-cy={itemId}
      onClick={() => onClick(visibility)}
      onKeyDown={(ev: React.KeyboardEvent) => {
        ev.code === "Enter" && onClick(visibility);
      }}
      data-testid="card"
      role="button"
      tabIndex={0}
      className="card"
      visible={visible}
      selected={selected}
    >
      <div className="header">
        <div>{title}</div>
        <div className="visible">visible: {JSON.stringify(visible)}</div>
        <div className="selected">selected: {JSON.stringify(!!selected)}</div>
      </div>
      <div className="background" />
    </div>
  );
}

const getId = (index: number) => `${"test"}${index}`;

const getItems = () =>
  Array(10)
    .fill(0)
    .map((_, ind) => ({ id: getId(ind) }));

function onWheel(apiObj: publicApiType, ev: React.WheelEvent): void {

  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else {
    apiObj.scrollPrev();
  }
}
