import { toast } from "react-hot-toast";
import { v4 as uuidv4 } from 'uuid';
import { CodeGenerationParams, generateCode } from '../../Components/AppGenerator/helpers/generateCode';
import { HistoryItem } from '../../Components/AppGenerator/history/history_types';
import { describeImageForAppGen, uploadImageToS3 } from './utils';
import { ChatMessage } from './interface';
import { RUNPOD_BASE_URL } from '../../Utils/Utils';
import { RefObject } from "react";

// Utility function to classify voice and set typing state
export const handleVoiceClassification = (
  isVoiceClassified: boolean,
  setIsTyping: (value: boolean) => void
): boolean => {
  if (!isVoiceClassified) {
    toast.error(
      "Voice not classified, try again in a moment or contact support"
    );
    setIsTyping(false);
    return false;
  }
  return true;
};

// Utility function to handle streaming and refIndex increment
export const handleStreamingAndRefIndex = (
  isStreaming: boolean[],
  refIndex: number,
  hasRan: boolean,
  setIsStreamingForIndex: (index: number, value: boolean) => void
): number => {
  if (isStreaming[refIndex]) {
    isStreaming[refIndex] = false;
    toast.error("Stopping & sending new message");
  }

  if (hasRan) {
    refIndex += 1;
  }
  setIsStreamingForIndex(refIndex, true);

  return refIndex;
};

// Utility function to handle chat history update
export const updateChatHistory = async (
  params: CodeGenerationParams,
  chatHistory: ChatMessage[],
  api_key: string
): Promise<ChatMessage[]> => {
  let newChatHistory;

  if (params.inputType === "image" && params.generationType === "create") {
    try {
      let opinion = await describeImageForAppGen(params, api_key);
      newChatHistory = [
        ...chatHistory,
        {
          text: opinion,
          sender: "user",
          profile: "/images/profile.png",
          audioUrl: "",
        },
      ];
    } catch (error) {
      console.error("Failed to get opinion:", error);
    }
  } else if (
    params.inputType === "text" &&
    params.generationType === "create"
  ) {
    newChatHistory = [
      ...chatHistory,
      {
        text: params.textPrompt,
        sender: "user",
        profile: "/images/profile.png",
        audioUrl: "",
      },
    ];
  } else {
    newChatHistory = [
      ...chatHistory,
      {
        text: params.textPrompt
          ? params.textPrompt
          : params.history[params.history.length - 1],
        sender: "user",
        profile: "/images/profile.png",
        audioUrl: "",
      },
    ];
  }

  return newChatHistory;
};

// Utility function to play audio on iPhone
// export const playAudioOnIphone = (
//   searchParams: URLSearchParams,
//   audioContainerRef: React.RefObject<HTMLDivElement>,
//   setTalkingState: any
// ) => {
//   const src = `${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`;
//   let audioElement = audioContainerRef.current.querySelector('audio');
//   let sourceElement = audioElement.querySelector('source');
//   sourceElement.src = src;
//   audioElement.load();

//   audioElement.addEventListener('ended', () => {
//     audioElement.pause();
//     // audioContainerRef.current.innerHTML = '';
//   });

//   audioElement.addEventListener('loadeddata', () => {
//     audioElement.play();
//     // audioElement.src = '';
//     // setAudioSrc(null);
//     // toast.error("Audio loadeddata");


//     // audioContainerRef.current.innerHTML = '';
//   });

//   audioElement.addEventListener('canplay', () => {
//     // toast.error("Audio canplay");
//     // audioElement.src = '';
//     // setAudioSrc(null);
//     // toast.error("Audio canplay");
//     audioElement.play();
//   });

//   audioElement.addEventListener('progress', () => {
//     // audioElement.src = '';
//     // setAudioSrc(null);
//     // toast.error("Audio onprogress");
//     audioElement.play();
//   });

//     // Add canplaythrough event listener to play the audio when it is ready
//   audioElement.addEventListener('canplaythrough', () => {
//     audioElement.play();
//     // toast.error("Audio canplaythrough");
//   });

//   audioElement.addEventListener('loadedmetadata', () => {
//     // audioElement.src = '';
//     // setAudioSrc(null);
//     // toast.error("Audio loadedmetadata");
//     audioElement.play();
//   });

//   audioElement.addEventListener('play', () => {
//     // toast.error("Audio is playing");
//   });

//   // if (audioContainerRef.current) {
    
//     // audioContainerRef.current.innerHTML = "";

//     // const audioElement = document.createElement("audio");
//     // audioElement.className = "w-full";
//     // audioElement.controls = true;
//     // audioElement.autoplay = true;

//     // const sourceElement = document.createElement("source");
//     // sourceElement.src = src;
//     // sourceElement.type = "audio/mpeg";

//     // audioElement.appendChild(sourceElement);
//     // audioContainerRef.current.appendChild(audioElement);

//     // audioElement.load();

//     // audioElement.addEventListener("ended", () => {
//     //   setTalkingState("idle");
//     //   audioElement.pause();
//     //   audioContainerRef.current.innerHTML = "";
//     // });

//     // audioElement.addEventListener("loadeddata", () => {
//     //   audioElement.play();
//     // });

//     // audioElement.addEventListener("canplay", () => {
//     //   audioElement.play();
//     // });

//     // audioElement.addEventListener("progress", () => {
//     //   audioElement.play();
//     // });

//     // audioElement.addEventListener("canplaythrough", () => {
//     //   audioElement.play();
//     // });

//     // audioElement.addEventListener("loadedmetadata", () => {
//     //   audioElement.play();
//     // });

//     // audioElement.addEventListener("play", () => {
//     //   setTalkingState("talking");
//     //   console.log("Audio is playing");
//     // });
//   // }
// };

export const playAudioOnIphone = (
  searchParams: URLSearchParams,
  audioContainerRef: React.RefObject<HTMLDivElement>,
  setTalkingState: any,
  onMetadataReady?: (metadata: any) => void,
  isHelloMsg: boolean = false
) => {
  const src = `${RUNPOD_BASE_URL}/say-prompt?${searchParams.toString()}`;
  let audioElement = audioContainerRef.current.querySelector('audio');
  let sourceElement = audioElement.querySelector('source');
  sourceElement.src = src;
  audioElement.load();

  // Function to extract and process metadata
  const extractMetadata = () => {
    if (!isHelloMsg) {
      const request = new XMLHttpRequest();
      request.open('GET', src, true);
      request.send();
      
      request.onreadystatechange = function() {
        if (this.readyState === this.HEADERS_RECEIVED) {
          const encodedMetadata = request.getResponseHeader('X-Metadata');
          if (encodedMetadata) {
            try {
              const decodedOnce = atob(encodedMetadata);
              const parsedData = JSON.parse(decodedOnce);
              let metadata;
              if (typeof parsedData === 'string') {
                metadata = JSON.parse(atob(parsedData));
              } else {
                metadata = parsedData;
              }
              
              if (onMetadataReady && typeof onMetadataReady === 'function') {
                onMetadataReady(metadata);
              }
            } catch (metadataError) {
              console.error("Error decoding metadata:", metadataError);
            }
          } else {
            console.log("No metadata found in response headers");
          }
          // Abort the request after getting headers to prevent downloading the full response
          request.abort();
        }
      };
    }
  };

  // Extract metadata immediately
  extractMetadata();

  audioElement.addEventListener('ended', () => {
    audioElement.pause();
    setTalkingState("idle");
  });

  audioElement.addEventListener('loadeddata', () => {
    audioElement.play();
  });

  audioElement.addEventListener('canplay', () => {
    audioElement.play();
  });

  audioElement.addEventListener('progress', () => {
    audioElement.play();
  });

  audioElement.addEventListener('canplaythrough', () => {
    audioElement.play();
  });

  audioElement.addEventListener('loadedmetadata', () => {
    audioElement.play();
  });

  audioElement.addEventListener('play', () => {
    // toast.error("Audio is playing");
  });

  // if (audioContainerRef.current) {

    // audioContainerRef.current.innerHTML = "";

    // const audioElement = document.createElement("audio");
    // audioElement.className = "w-full";
    // audioElement.controls = true;
    // audioElement.autoplay = true;

    // const sourceElement = document.createElement("source");
    // sourceElement.src = src;
    // sourceElement.type = "audio/mpeg";

    // audioElement.appendChild(sourceElement);
    // audioContainerRef.current.appendChild(audioElement);

    // audioElement.load();

    // audioElement.addEventListener("ended", () => {
    //   setTalkingState("idle");
    //   audioElement.pause();
    //   audioContainerRef.current.innerHTML = "";
    // });

    // audioElement.addEventListener("loadeddata", () => {
    //   audioElement.play();
    // });

    // audioElement.addEventListener("canplay", () => {
    //   audioElement.play();
    // });

    // audioElement.addEventListener("progress", () => {
    //   audioElement.play();
    // });

    // audioElement.addEventListener("canplaythrough", () => {
    //   audioElement.play();
    // });

    // audioElement.addEventListener("loadedmetadata", () => {
    //   audioElement.play();
    // });

    // audioElement.addEventListener("play", () => {
    //   setTalkingState("talking");
    //   console.log("Audio is playing");
    // });
  // }
};


// Utility function to play audio on non-iPhone devices
export const playAudioOnNonIphone = async (
  url: string,
  audioRef: React.RefObject<HTMLAudioElement>,
  onErrorInStream:any,
  setTalkingState: any,
  isMuted: any,
) => {
  try {
    const mediaSource = new MediaSource();
    const audioElement = audioRef.current;
    audioElement.src = URL.createObjectURL(mediaSource);
    const chunkTimeoutDuration = 5000; 
    let chunkTimeout: any; 

    mediaSource.addEventListener("sourceopen", async () => {
      const mimeCodec = "audio/mpeg";
      if (MediaSource.isTypeSupported(mimeCodec)) {
        const sourceBuffer = mediaSource.addSourceBuffer(mimeCodec);
        
        sourceBuffer.mode = 'sequence'; // Ensure sequential buffer appending
        
        try {
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const reader = response.body.getReader();
          const processChunk = async ({ done, value }) => {
            clearTimeout(chunkTimeout); // Clear the previous timeout
            // console.log(`Done: ${done}, Received chunk`);

            if (done) {
              mediaSource.endOfStream();
              return;
            } else {
              setTalkingState("talking");
            }

            // Reset the timeout for receiving the next chunk
            chunkTimeout = setTimeout(() => {
              // console.log("No new chunks received for 5 seconds. Assuming stream is done.");
              // If no new chunk is received for 5 seconds, assume done and stop the stream
              reader.cancel(); // Optionally cancel the reader to stop further reads
              processChunk({ done: true, value: null }); // Manually call with done = true
            }, chunkTimeoutDuration);

            // Wait for any ongoing updates to finish before appending new data
            await new Promise((resolve) => {
              if (!sourceBuffer.updating) {
                resolve(); // If no update is in progress, resolve immediately
              } else {
                const onUpdateEnd = () => {
                  sourceBuffer.removeEventListener("updateend", onUpdateEnd);
                  resolve(); // Resolve once updating has finished
                };
                sourceBuffer.addEventListener("updateend", onUpdateEnd);
              }
            });

            // Append the buffer and continue reading
            try {
              sourceBuffer.appendBuffer(value);
            } catch (e) {
              console.error("Error appending buffer:", e);
              onErrorInStream(`Buffer append error: ${e.message}`);
            }

            reader.read().then(processChunk);
          };

          reader.read().then(processChunk);
        } catch (fetchError) {
          if(!isMuted) {
            console.error("Fetch error:", fetchError);
            toast.error(`Fetch error: ${fetchError.message}`);
            onErrorInStream(`Fetch error: ${fetchError.message}`);
          }
          
          return;
        }
      } else {
        console.error(`MIME type ${mimeCodec} is not supported`);
        toast.error(`MIME type ${mimeCodec} is not supported`);
        throw new Error(`MIME type ${mimeCodec} is not supported`);
      }
    });

    if(isMuted == false) {
      await audioElement.play().then(() => {
        setTalkingState("talking");
        
        audioElement.addEventListener('ended', () => {
          setTalkingState("idle");
        });
      }).catch((playError) => {
        if (playError.name === "AbortError") {
          console.warn("Audio playback was aborted:", playError);
          onErrorInStream(playError);
          setTalkingState("idle");
        } else {
          console.error("Playback error:", playError);
          toast.error(`Playback error: ${playError.message}`);
          onErrorInStream(playError);
          setTalkingState("idle");
        }
      });
    }
    // await audioElement.play().then(() => {
    //   setTalkingState("talking");
      
    //   audioElement.addEventListener('ended', () => {
    //     setTalkingState("idle");
    //   });
    // }).catch((playError) => {
    //   if (playError.name === "AbortError") {
    //     console.warn("Audio playback was aborted:", playError);
    //     onErrorInStream(playError);
    //     setTalkingState("idle");
    //   } else {
    //     console.error("Playback error:", playError);
    //     toast.error(`Playback error: ${playError.message}`);
    //     onErrorInStream(playError);
    //     setTalkingState("idle");
    //   }
    // });
  } catch (error) {
    console.error("Playback error:", error);
    toast.error(`Playback error: ${error.message}`);
    throw error; // Re-throw the error to be caught by the parent try-catch
  }
};

// Function to process and play a chunk of audio
async function processAudioChunk(pcmData: Uint8Array, audioContext: AudioContext, wavHeader: any, onErrorInStream: any): Promise<AudioBufferSourceNode> {
  try {
    const audioBuffer = await createAudioBufferFromPCM(pcmData, audioContext, wavHeader);

    // Create and play the audio buffer immediately
    const bufferSource = audioContext.createBufferSource();
    bufferSource.buffer = audioBuffer;
    bufferSource.connect(audioContext.destination);
    bufferSource.start();

    // console.log("Chunk played");

    return bufferSource; // Return the source buffer for further processing
  } catch (decodeError) {
    console.error("Error processing audio chunk:", decodeError);
    onErrorInStream(decodeError);
    return null;
  }
}

// Function to parse the WAV header
function parseWavHeader(data: Uint8Array) {
  if (data.length < 44) {
    console.error("WAV header is too short");
    return null;
  }

  const view = new DataView(data.buffer);
  const channels = view.getUint16(22, true); // Number of channels
  const sampleRate = view.getUint32(24, true); // Sample rate
  const bitsPerSample = view.getUint16(34, true); // Bit depth
  return { channels, sampleRate, bitsPerSample };
}

// Function to convert raw PCM data into an AudioBuffer
async function createAudioBufferFromPCM(pcmData: Uint8Array, audioContext: AudioContext, wavHeader: any): Promise<AudioBuffer> {
  const { channels, sampleRate, bitsPerSample } = wavHeader;

  const numOfSamples = pcmData.length / (bitsPerSample / 8); // Number of samples
  const audioBuffer = audioContext.createBuffer(channels, numOfSamples, sampleRate);

  // Iterate through each channel and assign PCM data to the AudioBuffer
  for (let channel = 0; channel < channels; channel++) {
    const channelData = audioBuffer.getChannelData(channel);
    for (let i = 0; i < numOfSamples; i++) {
      const sampleIndex = i * (channels * (bitsPerSample / 8)) + channel * (bitsPerSample / 8);
      const sample = (pcmData[sampleIndex + 1] << 8) | (pcmData[sampleIndex] & 0xff); // Little-endian
      channelData[i] = sample / 32768.0; // Normalize the sample for 16-bit audio
    }
  }

  return audioBuffer;
}

function mapRagSourceType(sourceType: number): string {
  switch (sourceType) {
    case 1:
      return "link";
    case 2:
      return "youtube";
    case 3:
      return "pdf";
    case 4:
      return "csv";
    default:
      return "unknown";
  }
} 



// Utility function to handle code generation logic
export const generateCodeLogic = (
  params: CodeGenerationParams,
  parentVersion: number | null,
  settings: any,
  wsRef: React.RefObject<WebSocket>,
  setGeneratedCode: React.Dispatch<React.SetStateAction<string>>,
  setAppHistory: React.Dispatch<React.SetStateAction<HistoryItem[]>>,
  setCurrentVersion: any,
  setExecutionConsole: any,
  setAppState: any,
  setIsTyping: React.Dispatch<React.SetStateAction<boolean>>,
  updateInstruction: string,
  imageFilesWithPreviews: any[],
  AppState: any
) => {
  const updatedParams = { ...params, ...settings };
  setIsTyping(false);

  let accumulatedCode = '';

  generateCode(
    wsRef,
    updatedParams,
    (token) => {
      accumulatedCode += token;
      setGeneratedCode(accumulatedCode);
    },
    (code) => {
      setGeneratedCode(code);

      if (params.generationType === "create") {
        const inputs =
          params.inputType === "image"
            ? { image_url: imageFilesWithPreviews[0].dataUrl }
            : { text: params.textPrompt };
        setAppHistory([
          {
            type: "ai_create",
            parentIndex: null,
            code,
            inputs,
          },
        ]);
        setCurrentVersion(0);
      } else {
        setAppHistory((prev) => {
          if (parentVersion === null) {
            toast.error("Error on Updating your prompt");
            return prev;
          }

          const newHistoryItem: HistoryItem = {
            type: "ai_edit" as const, 
            parentIndex: parentVersion,
            code,
            inputs: {
              prompt: updateInstruction,
            },
          };

          const newHistory = [...prev, newHistoryItem];
          setCurrentVersion(newHistory.length - 1);
          return newHistory;
        });
      }
    },
    (line) => setExecutionConsole((prev) => [...prev, line]),
    () => {
      setAppState(AppState.CODE_READY);
    }
  );
};


export const handleImageUpload = async (
  imageForS3Upload: any
): Promise<string> => {
  return await uploadImageToS3(imageForS3Upload);
};



export function prepareAudioSearchParams(uuid, chatHistory, inputPrompt, chatbotData, selectedVoice, isUserOnIphone, selectedModelOption, imageVision = false, selectedLanguageOption, isMuted) {
  const searchParams = new URLSearchParams();
  searchParams.set("prompt", ""); // Adjust accordingly if you need to pass specific prompts
  searchParams.set("responseId", uuid);
  searchParams.set("history", JSON.stringify(chatHistory));
  searchParams.set("selectedVoice", selectedVoice);
  searchParams.set("introMsg", "true");
  searchParams.set("chatId", chatbotData.id);
  searchParams.set("isRAG", chatbotData.rag.toString());
  searchParams.set("ragSource", chatbotData.rag_source);
  searchParams.set("ragSourceType", mapRagSourceType(chatbotData.rag_source_type));
  searchParams.set("imageVision", imageVision.toString());
  searchParams.set("isIphone", isUserOnIphone.toString());
  searchParams.set("selectedModel", selectedModelOption);
  searchParams.set("language", selectedLanguageOption);
  searchParams.set("isMuted", isMuted.toString());
  return searchParams;
}


export function setupAudioPlayback(audioContainerRef, src) {
  if (audioContainerRef.current) {
    let audioElement = audioContainerRef.current.querySelector("audio") || document.createElement("audio");
    if (!audioContainerRef.current.contains(audioElement)) {
      audioContainerRef.current.appendChild(audioElement);
    }
    
    let sourceElement = audioElement.querySelector("source") || document.createElement("source");
    if (!audioElement.contains(sourceElement)) {
      audioElement.appendChild(sourceElement);
    }

    sourceElement.src = src;
    audioElement.load();

    const playAudio = () => {
      audioElement.play().catch(error => console.error("Playback failed:", error));
    };

    // Event listeners
    audioElement.addEventListener("ended", () => {
      audioContainerRef.current.innerHTML = ''; // Clear audio element after playback
    });
    audioElement.addEventListener("loadeddata", playAudio);
    audioElement.addEventListener("canplay", playAudio);
    audioElement.addEventListener("progress", playAudio);
    audioElement.addEventListener("canplaythrough", playAudio);
    audioElement.addEventListener("loadedmetadata", playAudio);
  }
}

export function manageAudioRef(audioRef, src, setLoading, setTalkingState, toast) {
  if (!audioRef.current) return;
  const audioElement = audioRef.current;

  audioElement.src = src;
  const onError = () => {
    setLoading(false);
    setTalkingState("idle");
    console.error("Error loading audio");
    toast.error("Error loading audio, browser not supported");
  };

  // Setting up event listeners
  audioElement.onerror = onError;
  audioElement.onloadeddata = () => audioElement.play();
  audioElement.oncanplay = () => audioElement.play();
  audioElement.onprogress = () => audioElement.play();
  audioElement.oncanplaythrough = () => audioElement.play();
}